import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import Reveal from "react-reveal/Reveal";
import Container from "../core/Container";

const fadeInUp = keyframes`
0% {
    opacity: 0;
    -webkit-transform: translate3d(0,5%,0);
    transform: translate3d(0,5%,0);
}

50% {
    opacity: 0.8;
    -webkit-transform: translate3d(0,2%,0);
    transform: translate3d(0,2%,0);
}

100% {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
}
`;

const fadeInLeft = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-5%,0,0);
    transform: translate3d(-5%,0,0);
  }

  50% {
    opacity: 0.8;
    -webkit-transform: translate3d(-2%,0,0);
    transform: translate3d(-2%,0,0);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
`;

const fadeInRight = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translate3d(5%,0,0);
    transform: translate3d(5%,0,0);
  }

  50% {
    opacity: 0.8;
    -webkit-transform: translate3d(2%,0,0);
    transform: translate3d(2%,0,0);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
`;

const Animation = styled.div`
  .fadeInUp {
    animation: ${fadeInUp} ease-in;
  }

  .fadeInLeft {
    animation: ${fadeInLeft} ease-in;
  }

  .fadeInRight {
    animation: ${fadeInRight} ease-in;
  }
`;

const PageLoadDelayedAnimation = ({ children }) => {
  const [play, setPlay] = useState(false);

  useEffect(() => {
    const container = Container.getInstance();
    const manager = container.getPageUpdateManager();

    manager.onPageLoad(() => setPlay(true));
  }, []);

  return <Animation>{children({ play })}</Animation>;
};

export const HeroFadeInUp = ({ children }) => (
  <PageLoadDelayedAnimation>
    {({ play }) => (
      <FadeInUp when={play} appear>
        {children}
      </FadeInUp>
    )}
  </PageLoadDelayedAnimation>
);

export const FadeInUp = ({ children, ...rest }) => (
  <Animation>
    <Reveal effect="fadeInUp" cascade duration={500} fraction={0.4} {...rest}>
      {children}
    </Reveal>
  </Animation>
);

export const FadeInLeft = ({ children, ...rest }) => (
  <Animation>
    <Reveal effect="fadeInLeft" cascade duration={500} fraction={0.4} {...rest}>
      {children}
    </Reveal>
  </Animation>
);

export const FadeInRight = ({ children, ...rest }) => (
  <Animation>
    <Reveal effect="fadeInRight" cascade duration={500} fraction={0.4} {...rest}>
      {children}
    </Reveal>
  </Animation>
);
